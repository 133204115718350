import React from "react"
import {graphql} from "gatsby"
import get from 'lodash/get'

import Layout from '../components/Layout'
import CenteredSection from '../components/CenteredSection'
import ContentSectionRichText from '../components/ContentSectionRichText'

import { GatsbyImage, getImage } from "gatsby-plugin-image"

import '../scss/header.scss'
import '../scss/articles.scss'

import calendarLogo from '../images/calendar.svg'
import avatarLogo from '../images/avatar2.svg'

const BlogPost = (props) => {

  const data = props.data
  const post = get(data, 'contentfulBlogPost')

  return (<Layout activePage={post.slug} title={post.seoTitle} description={post.seoDescription}>
    <CenteredSection>
      <div className="articles">
        <h1>{post.title}</h1>
        {!!post.subtitle && <div className="articleSubtitle">{post.subtitle}</div>}
        <div className="heroImageHeader">
          {!!post.author?.fullName && <div className="authorName"><img src={avatarLogo} alt="" />{post.author.fullName}{post.author.position && ' - ' + post.author.position}</div>}
          {!!post.publishDate && <div className="publicationDate"><img src={calendarLogo} alt="" />{post.publishDate}</div>}
        </div>
        {!!post.heroImage?.gatsbyImageData && <GatsbyImage 
          image={getImage(post.heroImage.gatsbyImageData)}
          alt={post.heroImage.description ?? "Laser Metal Cutting"}
          loading="lazy"
          placeholder="blurred" />}
        {!!post.content?.raw && (<ContentSectionRichText description={post.content} />)}
      </div>
    </CenteredSection>
  </Layout>)
}

export default BlogPost

export const postQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      slug
      title
      seoTitle
      seoDescription
      subtitle
      publishDate(formatString: "DD MMM YYYY", locale: "MST")
      author {
        fullName
        position
      }
      heroImage {
        gatsbyImageData(width: 800, placeholder: BLURRED, quality: 80)
        description
      }
      description {
        description
      }
      content {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            id
            contentful_id
            gatsbyImageData(width: 600, placeholder: BLURRED, quality: 80)
            description
          }
          ... on ContentfulImage {
            id
            name
            source {
              gatsbyImageData(width: 800, placeholder: BLURRED, quality: 80)
            }
            alt
            caption
            contentful_id
            display
            size
            position
          }
        }
      }
    }
  }
`

/*
content {
        raw
        references {
          __typename
          ... on ContentfulAsset {
            id
            contentful_id
            gatsbyImageData(width: 600, placeholder: BLURRED, quality: 80)
          }
          ... on ContentfulImage {
            id
            name
            source {
              gatsbyImageData
            }
            alt
            caption
            contentful_id
            display
            size
          }
        }
      }
      */